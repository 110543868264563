import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { Environmenter } from './environmenter.service';
import { GlobalService } from '../admin/GlobalService/global-service';
import { ApplicationInsightsService } from '../ApplicationInsightsService';
@Injectable()
export class AuthenticationService {
  _environment: any;
  _oauthData = { isAuthenticated: false, user: null, loginError: "", idToken: {} };
  private loginScopes: string[];
  _renewActive: boolean;
  
  constructor(private environment: Environmenter, private http: HttpClient, private router: Router,
    private global: GlobalService,
    private _appInsightService: ApplicationInsightsService,
    handler: HttpBackend
  ) {
    this._environment = environment.getGlobalEnvironment();
    this.http = new HttpClient(handler)
  }

  updateDataFromCache(rl_protocol: any) {
    var adata = this._environment.cacheLocation.getItem(rl_protocol['applicationId']);
    if (adata !== null) {
      this._oauthData = adata && JSON.parse(this.decrypt(adata)) || this._oauthData;
      this._oauthData.isAuthenticated = this._oauthData && this.isSessionValid() || false;
      if (this._oauthData.isAuthenticated === false) {
        this.loginRedirect(this._environment[this._environment.rl_protocol])
      }
    }
    else {
      this._oauthData.isAuthenticated = false;
      this.loginRedirect(this._environment[this._environment.rl_protocol])
    }
  }
  public loginRedirect(rl_protocol: any) {
    this._environment.cacheLocation.clear();
    this._oauthData.isAuthenticated = false;
    this._oauthData.user = null;
    //this.router.navigate(['/login']);
    //this.router.navigate([this._environment.lang + '/' + this._environment.authentication + '/signin']);
  }

  public studentLogOut(rl_protocol: any) {
    this._environment.cacheLocation.clear();
    this._oauthData.isAuthenticated = false;
    this._oauthData.user = null;
    this.router.navigate(['/']);
  }

  public logout(): void {
    this._oauthData.isAuthenticated = false;
    this._oauthData.user = null;
    this.router.navigate([this._environment.lang + '/' + this._environment.authentication + '/signout']);
  }
  public acquireTokenSilent(authority: any) {
    return new Promise((resolve, reject) => {
      let body = 'grant_type=password&username=' + this._oauthData.user.username + '&password=' + this._oauthData.user.password + '&scope=read';// JSON.stringify({ grant_type:"password",username:username,password:password });
      let httpOptions = {
        headers: new HttpHeaders({
          'scope-header': 'x-auth-request',
          'Content-Type': 'application/x-www-form-urlencoded',
          'con':this._environment.con || '',
          'Authorization': "Basic " + btoa((authority['applicationId'] + ":" + authority['clientSecret'])),
        })
      };
      this.http.post(this._environment.base + "/oauth/token", body, httpOptions).toPromise().then((token: any) => {
        this._renewActive = false;
        this._oauthData = token;
        this._oauthData.isAuthenticated = token && this.isSessionValid() || false;
        this._environment.cacheLocation.setItem(authority['applicationId'], this.encrypt(JSON.stringify(this._oauthData)));
        this.global.utilities.storage.set('mi', token.module_code)
        this.global.utilities.storage.set('org_id', 1)
        this.global.utilities.storage.set('initial_user_route', token.redirect_url);
         resolve(true);
      }, (error: any) => {
        console.log(error);
        this._renewActive = false;
        reject(false);
      })
    });
  }
  acquireToken(username: string, password: string) {
    this._environment.cacheLocation.removeItem(this._environment[this._environment.rl_protocol]['applicationId']);
    return new Promise((resolve, reject) => {
      let authority: any = this._environment[this._environment.rl_protocol];
      let body = 'grant_type=password&username=' + username + '&password=' + password + '&scope=read';// JSON.stringify({ grant_type:"password",username:username,password:password });
      let httpOptions = {
        headers: new HttpHeaders({
          'scope-header': 'x-auth-request',
          'Content-Type': 'application/x-www-form-urlencoded',
          'con':this._environment.con || '',
          'Authorization': "Basic " + btoa((authority['applicationId'] + ":" + authority['clientSecret'])),
        })
      };
      this.http.post(this._environment.base + "/oauth/token", body, httpOptions).toPromise().then((token: any) => {
        if (token.status == "1") {
          debugger
          this._renewActive = false;
          this._oauthData = token;
          this._oauthData.isAuthenticated = token && this.isSessionValid() || false;
          this._appInsightService.setUserId(token.user.username);
          this._environment.cacheLocation.setItem(authority['applicationId'], this.encrypt(JSON.stringify(this._oauthData)));
          if (this._oauthData.isAuthenticated) {
            resolve(token);
          }
        }
        else {
          resolve(token);
        }
      }, (error: any) => {
        console.log(error);
        this._renewActive = false;
        reject(false);
      })
    });
  }
  acquiresamlToken(useridentity: string) {
    this._environment.cacheLocation.removeItem(this._environment[this._environment.rl_protocol]['applicationId']);
    return new Promise(async (resolve, reject) => {
      let authority: any = this._environment[this._environment.rl_protocol];
      let body = 'Samluser=' + useridentity;
      let httpOptions = {
        headers: new HttpHeaders({
          'scope-header': 'x-auth-request',
          'Content-Type': 'application/x-www-form-urlencoded',
          'con':this._environment.con || '',
          'Authorization': "Basic " + btoa((authority['applicationId'] + ":" + authority['clientSecret'])),
        })
      };

      await this.http.post(this._environment.base + "/oauth/samltoken", body, httpOptions).toPromise().then((token: any) => {
        document.querySelector('section').innerHTML = "";
        this._renewActive = false;
        this._oauthData = token;
        this._oauthData.isAuthenticated = token && this.isSessionValid() || false;
        this._environment.cacheLocation.setItem(authority['applicationId'], this.encrypt(JSON.stringify(this._oauthData)));
        this.global.utilities.storage.set('mi', token.module_code)
        this.global.utilities.storage.set('org_id', 1)
        this.global.utilities.storage.set('initial_user_route', token.redirect_url);

        if (this._oauthData.isAuthenticated) {
          resolve(true);
        } else { resolve(false); }
      }, (error: any) => {
        console.log(error);
        this._renewActive = false;
        reject(false);
      })
    });
  }


  studentToken(username: string, password: string,org_id: number) {
    
    this._environment.cacheLocation.removeItem(this._environment[this._environment.rl_protocol]['applicationId']);
    return new Promise((resolve, reject) => {
      let authority: any = this._environment[this._environment.rl_protocol];
      let body = 'grant_type=password&Mobile_No=' + username + '&password=' + password + '&scope=read&ORG_ID='+org_id;// JSON.stringify({ grant_type:"password",username:username,password:password });
      let httpOptions = {
        headers: new HttpHeaders({
          'scope-header': 'x-auth-request',
          'Content-Type': 'application/x-www-form-urlencoded',
          'con':this._environment.con || '',
          'Authorization': "Basic " + btoa((authority['applicationId'] + ":" + authority['clientSecret'])),
        })
      };
      this.http.post(this._environment.base + "/oauth/studenttoken", body, httpOptions).toPromise().then((token: any) => {
        if (token.status == "1") {
        
          debugger
          this._renewActive = false;
          this._oauthData = token;
          this._oauthData.isAuthenticated = token && this.isSessionValid() || false;
          this._appInsightService.setUserId(token.user.username);
          this._environment.cacheLocation.setItem(authority['applicationId'], this.encrypt(JSON.stringify(this._oauthData)));
          if (this._oauthData.isAuthenticated) {
            resolve(token);
          }
        }
        else {
          resolve(token);
        }
      }, (error: any) => {
        console.log(error);
        this._renewActive = false;
        reject(false);
      })
    });
  }

  SAMLLogin() {
    this._environment.cacheLocation.removeItem(this._environment[this._environment.rl_protocol]['applicationId']);
    return new Promise((resolve, reject) => {
      let authority: any = this._environment[this._environment.rl_protocol];
      let body = 'grant_type=password&username=&password=&scope=read';// JSON.stringify({ grant_type:"password",username:username,password:password });
      let httpOptions = {
        headers: new HttpHeaders({
          'scope-header': 'x-auth-request',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': "Basic " + btoa((authority['applicationId'] + ":" + authority['clientSecret'])),
        })
      };
      // this.http.get(this._environment.base + "/Account/ExternalLogin").toPromise().then((token: any) => {
      //   this._renewActive = false;
      //   this._oauthData = token;
      //   this._oauthData.isAuthenticated = token && this.isSessionValid() || false;
      //   this._environment.cacheLocation.setItem(authority['applicationId'], this.encrypt(JSON.stringify(this._oauthData)));
      //   if (this._oauthData.isAuthenticated) {
      //     resolve(true);
      //   } else { resolve(false); }
      // }, (error: any) => {
      //   console.log(error);
      //   this._renewActive = false;
      //   reject(false);
      // })
      window.location.href = this._environment.base + "/Account/ExternalLogin?provider=saml2&returnUrl=" + this._environment.clientbase + "/samllogin";

    });
  }

  navigateToHome() {
    this.router.navigate([this._environment.lang + '/' + this._environment.support + '/home']);
  }
  GoToMainPage() {

   //this.router.navigate(['/member/test']);
   //exams/instruction
   //?ic=484DD2DE-3245-4550-903B-0CEB09E39D4B&id=383
   this.router.navigate([`exams/instruction`], { queryParams: { ic: 'B4312DC1-5393-42F2-B23A-D9B34562A7EE',id:258 } });
  }
  isSessionValid() {
    let CurrentMs = new Date().getTime();
    let expirationMS = new Date(this._oauthData.idToken['expires_at']).getTime();
    let diff = expirationMS - CurrentMs;
    return diff >= 0;
  }
  //The set method is use for encrypt the value.
  encrypt(value) {
    var ciphertext = CryptoJS.AES.encrypt(value, this._environment.salt);
    return ciphertext.toString();
  }
  //The get method is use for decrypt the value.
  decrypt(value) {
    var bytes = CryptoJS.AES.decrypt(value, this._environment.salt);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
