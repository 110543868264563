import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/autnentication-service';
import { GlobalService } from '../admin/GlobalService/global-service';
import { AppLoaderServiceMessaging } from '../Shared/app-loader-service-messaging';
import { helper } from '../Shared/helper';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-samlauthentication',
  templateUrl: './samlauthentication.component.html',
  styleUrls: ['./samlauthentication.component.css']
})
export class SamlauthenticationComponent implements OnInit {
  _env: any;
  IsLoading: boolean;
  user = { username: "8750034235", password: "123456", org_id: 0 }
  userroleList: any = [];
  helper = new helper();
  internal_code: any;
  role_id: any = "0";
  _environment: any;
  org_code: any = "BEML";
  org_details: any;
  org_List: any;
  private http: HttpClient

  constructor(private auth: AuthenticationService, private global: GlobalService,
    private activated:ActivatedRoute,
    private _loader_service: AppLoaderServiceMessaging) {


  }
  async ngOnInit() {
    this.org_code=this.activated.snapshot.paramMap.get('org_code');
    this.LoadClientApplication();
    setTimeout(() => {
      // this.login();
    }, 500);
    //   this.login();
  }

  async login() {
    // debugger;
    // this._loader_service.show_loader();

    $(".preloader").fadeOut();
    let valid = true;

    if(this.user.org_id==0)
    {
      this.global.utilities.notify.error('Please select Institute/College/University ', 'Error');
      return;
    }
    if(this.user.username.trim()=='')
    {
      this.global.utilities.notify.error('Please enter Email/Enrollment No', 'Error');
      return;
    }
    if(this.user.password.trim()=='')
    {
      this.global.utilities.notify.error('Please enter Password', 'Error');
      return;
    }
    if (valid === true) {
      this.IsLoading = true;
      await this.auth.studentToken(this.user.username, this.user.password, this.user.org_id).then((token: any) => {
        if (token.status == "1") {

          this._loader_service.hide_loader(true);
          this.global.utilities.storage.set('mi', token.module_code)
          this.global.utilities.storage.set('org_id', token.user.id.toString())
          this.global.utilities.storage.set('initial_user_route', token.redirect_url);
          this.auth.GoToMainPage();
        }
        else {
          this.IsLoading = false;
          this._loader_service.hide_loader(true);
          this.global.utilities.notify.error(token.message, 'Error');
        }
      }, (error: any) => {
        this.IsLoading = false;
        this.global.utilities.notify.error(error, 'Error');
        console.log(error);
      });
    }
  }

  LoadClientApplication() {
   
    this.global.ServiceManager.request.get('Common/GetOrgDetails?org_code=' + this.org_code).subscribe(
      (res: any) => {
       this.org_List=res;
        if(res.length>0)
        {
          this.org_details =res[0];
          this.user.org_id = this.org_details.org_id;
        
        }
       
      });
  }
  BindOrgDetails()
  {
    this.org_details =this.org_List.filter(x=>x.org_id== this.user.org_id)[0];
  }
}
