import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from './authentication/authentication-interceptor';
import { environment } from '../environments/environment';
import { ENVIRONMENTER } from '../environments/environmenter.token';
import { AuthenticationService } from './authentication/autnentication-service';
import { AuthenticationGuard } from './authentication/authentication-guard';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SamlauthenticationComponent } from './samlauthentication/samlauthentication.component';
import { FormsModule } from '@angular/forms';
import { ApplicationInsightsService } from './ApplicationInsightsService';
import { EnvServiceProvider } from './env.service.provider';
@NgModule({
  declarations: [
    AppComponent,
    SamlauthenticationComponent,
 
    // LssiFileLogComponent
    ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,BrowserAnimationsModule,FormsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }), NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [AuthenticationService,AuthenticationGuard,HttpClient,EnvServiceProvider, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
    { provide: ENVIRONMENTER, useValue: environment },ApplicationInsightsService],
  bootstrap: [AppComponent],

})
export class AppModule { }
